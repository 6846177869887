import React from "react";
import {
  InstantSearch,
  Configure,
  Index,
  connectStateResults,
} from "react-instantsearch-dom";

import Layout from "@components/layout";

import { searchClient } from "@lib/algolia";
import {
  CustomSearchBox,
  ProductSearchHits,
  StandardSearchHits,
} from "@components/search";

const IndexResults = connectStateResults(
  ({ searchState, searchResults, children }) =>
    searchState.query && searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <></>
    )
);

const SearchPage = () => {
  return (
    <Layout isProduct hideSub hasTransparentHeader>
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.GATSBY_ALGOLIA_PAGE_INDEX}
      >
        <div className="px-gutter pt-32 pb-24 bg-black text-white md:py-48">
          <div className="max-w-4xl mx-auto">
            <CustomSearchBox isLarge />
          </div>
        </div>
        <Index indexName={process.env.GATSBY_ALGOLIA_PRODUCTS_INDEX}>
          <IndexResults>
            <div className="px-gutter py-10">
              <h2 className="blockH2">Products</h2>
            </div>
            <ProductSearchHits />
          </IndexResults>
        </Index>
        <Index indexName={process.env.GATSBY_ALGOLIA_PAGE_INDEX}>
          <IndexResults>
            <StandardSearchHits />
          </IndexResults>
        </Index>
        <Configure hitsPerPage={600} />
      </InstantSearch>
    </Layout>
  );
};

export default SearchPage;
